<template>
  <div>
    <div class="md-layout">
      <div class="md-layout-item md-size-100 mb-5">
        <md-card>
          <md-card-header>
            <h4 class="title">
              <b>Timeline Form</b>
            </h4>
          </md-card-header>
          <md-card-content>
            <FormText
              v-model="form.message"
              label="Title"
              icon="description"
            />

            <md-button
              class="md-primary md-block md-sm"
              @click="postData"
            >
              SAVE
            </md-button>
          </md-card-content>
        </md-card>
      </div>
      <div class="md-layout-item md-size-100 text-center">
        <b>Categories</b>
        <div class="d-flex justify-content-center">
          <md-switch
            v-for="c of Object.keys(showedCategories)"
            :key="c"
            v-model="showedCategories[c]"
          >
            {{ c.toUpperCase() }}
          </md-switch>
        </div>
        <hr class="mb-0 mt-3">
      </div>
      <div class="md-layout-item md-size-100">
        <time-line
          plain
          class="pt-0 mt-0"
          type="simple"
        >
          <time-line-item
            v-for="item of items"
            :key="item.timeline_id"
            :class="noCategory || showedCategories[item.category] ? '' : 'hidden'"
            inverted
            :badge-type="colors[item.category]"
            :badge-icon="icons[item.category]"
          >
            <badge
              slot="header"
              :type="colors[item.category]"
            >
              {{ item.category === "applicationForm" ? "Application Form" : item.category }}
            </badge>
            <p slot="content">
              {{ item.message }}
            </p>

            <h6 slot="footer">
              <i class="ti-time" />
              {{ dateTimeFormat(item.created_at) }}
              <small v-if="item.user">- {{ item.user.name }}</small>
            </h6>
          </time-line-item>
        </time-line>
      </div>
    </div>
  </div>
</template>

<script>
import { TimeLine, TimeLineItem, Badge } from '@/components';
import { FormText } from '@/components/Inputs';

export default {
  components: {
    TimeLine,
    TimeLineItem,
    Badge,
    FormText,
  },
  data: () => ({
    loading: false,
    colors: {
      letter: 'info',
      programme: 'success',
      package: 'warning',
      payment: 'danger',
      file: 'primary',
      email: 'secondary',
      applicationForm: 'terciary',
      custom: 'default',
    },
    icons: {
      letter: 'drafts',
      programme: 'description',
      package: 'list_alt',
      payment: 'euro',
      file: 'attach_file',
      email: 'inbox',
      applicationForm: 'feed',
      custom: 'info',
    },
    items: [],
    showedCategories: {
      letter: false,
      programme: false,
      package: false,
      payment: false,
      file: false,
      email: false,
      applicationForm: false,
      custom: false,
    },
    form: {
      message: null,
    },
  }),
  computed: {
    noCategory() {
      let valid = true;

      // eslint-disable-next-line no-restricted-syntax
      for (const key in this.showedCategories) {
        if (Object.hasOwnProperty.call(this.showedCategories, key)) {
          if (this.showedCategories[key]) valid = false;
        }
      }

      return valid;
    },
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      this.loading = true;
      this.request(`${this.$API.STUDENTS + this.$route.params.id}/timeline`, 'get', {}, ({ data }) => {
        this.items = data;
      }, () => {
        this.loading = false;
      });
    },
    postData() {
      this.request(`${this.$API.STUDENTS + this.$route.params.id}/timeline`, 'post', { ...this.form }, () => {
        this.getData();
        this.form.message = null;
      });
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep .timeline {
  &::before {
    bottom: 150px;
  }
  .timeline-badge {
    &.secondary {
      background-color: #1e28b4;
      -webkit-box-shadow: 0 12px 20px -10px rgb(30 40 180 / 28%),
        0 4px 20px 0px rgb(0 0 0 / 12%), 0 7px 8px -5px rgb(30 40 180 / 20%);
      box-shadow: 0 12px 20px -10px rgb(30 40 180 / 28%),
        0 4px 20px 0px rgb(0 0 0 / 12%), 0 7px 8px -5px rgb(30 40 180 / 20%);
    }
    &.terciary {
      background-color: #bdd106;
      -webkit-box-shadow: 0 12px 20px -10px rgb(30 40 180 / 28%),
        0 4px 20px 0px rgb(0 0 0 / 12%), 0 7px 8px -5px rgb(30 40 180 / 20%);
      box-shadow: 0 12px 20px -10px rgb(30 40 180 / 28%),
        0 4px 20px 0px rgb(0 0 0 / 12%), 0 7px 8px -5px rgb(30 40 180 / 20%);
    }
    &.default {
      background-color: #999999;
      -webkit-box-shadow: 0 12px 20px -10px rgb(30 40 180 / 28%),
        0 4px 20px 0px rgb(0 0 0 / 12%), 0 7px 8px -5px rgb(30 40 180 / 20%);
      box-shadow: 0 12px 20px -10px rgb(30 40 180 / 28%),
        0 4px 20px 0px rgb(0 0 0 / 12%), 0 7px 8px -5px rgb(30 40 180 / 20%);
    }
  }
  .timeline-heading .badge {
    &.badge-secondary {
      background-color: #1e28b4;
    }
    &.badge-terciary {
      background-color: #bdd106;
    }
  }
  .timeline-inverted {
    max-height: 500px;
    transition: 0.25s ease-in;
    overflow: hidden;

    &.hidden {
      max-height: 0;
      transition: 0.15s ease-out;
      margin: 0;
    }
  }
}
</style>
